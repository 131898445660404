import axios from 'axios';


/**
 * Base API URL
 */
const API_URL = process.env.REACT_APP_API_URL;


/**
 * Default axios configuration
 */
const baseConfig = {
  baseURL: API_URL,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
};

const callApi = axios.create(baseConfig);

export default callApi;

// @flow

import invariant from 'invariant';


function media(minWidth: ?number = null, maxWidth: ?number = null) {
  const medias = [];

  invariant(minWidth || maxWidth, 'at least one of minWidth or maxWidth is required in media');

  if (minWidth) {
    medias.push(`(min-width: ${minWidth}px)`);
  }

  if (maxWidth) {
    medias.push(`(max-width: ${maxWidth}px)`);
  }

  return `@media ${medias.join(' and ')}`;
}

export default media;

// @flow

// As AppLoader is not wrapper in ThemeProvider we can't use a theme here
// but we can use it in any nested component
const styles = {
  app: {
    minHeight: '100vh',
    background: 'linear-gradient(150deg, #809dbd, #e8f0ff)',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: 48,
    color: '#010e4a',
  },

  wrapper: {
    width: '100%',
    maxWidth: 800,
    textAlign: 'center',
  },

  logo: {
    width: 360,
  },

  title: {
    fontWeight: 300,
    fontSize: 38,
  },

  error: {
    fontSize: 96,
    margin: 0,
  },
};

export default styles;

// @flow

const styles = () => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  main: {
    flex: '1 1 auto',
    width: '100%',
    maxWidth: 760,
    alignSelf: 'center',
    padding: [0, 12],
  },
});

export default styles;

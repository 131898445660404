// @flow

import React from 'react';
import cn from 'classnames';
import { withStyles } from '../../common/utils';
import styles from './StepError.jss';
import Button from '../../common/elements/Button';


type Props = Styles<typeof styles> & {
  onTryAgain: () => any,
  onReset: () => any,
  isFirstStep: boolean,
};

function StepError(props: Props) {
  const {
    onTryAgain,
    onReset,
    isFirstStep,
    classes,
  } = props;

  return (
    <div className={cn('stepError', classes.stepError)}>
      <div>
        <Button onClick={() => onTryAgain()}>Try again</Button>
      </div>

      {!isFirstStep && (
        <div>
          <Button
            onClick={() => onReset()}
            className={classes.cancel}
          >
            Cancel Check-In
          </Button>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(StepError);

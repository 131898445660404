// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import invariant from 'invariant';
import Root from './Root';
import AppLoader from './app/AppLoader';

const container = document.getElementById('root');

function render() {
  invariant(container, 'Couldn\'t find `root` element');

  ReactDOM.render((
    <Root>
      <AppLoader />
    </Root>
  ), container);
}

if (module.hot) {
  module.hot.accept('./app/AppLoader', () => {
    render();
  });
}

render();

// @flow

import React, { Component } from 'react';
import cn from 'classnames';
import { Route } from 'react-router-dom';
import invariant from 'invariant';
import { withStyles } from '../../common/utils';
import Box from '../Box';
import styles from './Step.jss';

type Props = {
  isActive: boolean,
  isDone: boolean,
  appState: {},
} & StepConfig & Styles<typeof styles>;


class Step extends Component<Props> {
  constructor(props) {
    super(props);

    const { key, enterApi, submitApi } = props;
    const requiredMethods = ['call', 'normalizeResponse', 'normalizeError'];

    // Check if onEnter config has all required methods
    if (enterApi) {
      requiredMethods.forEach(m => invariant(
        enterApi[m],
        `onEnter.${m} must be explicitly defined in ${key} api config`,
      ));
    }

    // Check if onSubmit config has all required methods
    if (submitApi) {
      requiredMethods.forEach(m => invariant(
        submitApi[m],
        `onSubmit.${m} must be explicitly defined in ${key} api config`,
      ));
    }
  }

  render() {
    const {
      appState,
      title,
      hint,
      getValue,
      path,
      view: View,
      classes,
      ...viewProps
    } = this.props;

    const value = viewProps.isDone && getValue && getValue(appState);

    return (
      <div
        className={cn('step', classes.step, {
          active: viewProps.isActive,
          done: viewProps.isDone,
        })}
      >
        <header className={cn('step-header', classes.header)}>
          <h2 className={cn('step-title', classes.title)}>{title}</h2>

          {viewProps.isActive ? (
            <h4 className={cn('step-hint', classes.hint)}>{hint}</h4>
          ) : value && (
            <h4 className={cn('step-value', classes.value)}>• {value}</h4>
          )}
        </header>

        <Route
          exact
          path={path}
          render={() => (
            <div className={cn('step-content', classes.content)}>
              <Box>
                <View appState={appState} {...viewProps} />
              </Box>
            </div>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Step);

// @flow

import './index.css';
import React, { Fragment, type ComponentType } from 'react';
import { jss } from 'react-jss';
import { Router } from 'react-router-dom';
import normalize from 'normalize-jss';
import history from './common/history';

jss.createStyleSheet(normalize).attach();

type Props = {
  children: ComponentType<any>,
};

function Root({ children }: Props) {
  return (
    <Router history={history}>
      <Fragment>
        {children}
      </Fragment>
    </Router>
  );
}

export default Root;

// @flow

import React, { type ComponentType } from 'react';
import cn from 'classnames';
import { withStyles } from '../../common/utils';
import styles from './Box.jss';


type Props = Styles<typeof styles> & {
  className?: string,
  children: ComponentType<any>,
};

function Box(props: Props) {
  const {
    classes,
    className,
    children,
    ...otherProps
  } = props;

  return (
    <div className={cn('box', className, classes.box)} {...otherProps}>
      {children}
    </div>
  );
}

export default withStyles(styles)(Box);

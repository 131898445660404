// @flow

const styles = () => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: [28, 0, 24],
    textAlign: 'center',
    background: 'linear-gradient(transparent, #000000)',
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: 12,

    '& a': {
      display: 'block',
      height: 24,
    },

    '& span': {
      marginRight: 8,
    },

    '& svg': {
      height: '100%',
    },
  },
});

export default styles;

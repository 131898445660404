// @flow

const styles = ({ colorError, colorWarning, colorSuccess }: Theme) => ({
  alert: {
    textAlign: 'center',
    padding: 10,
    margin: [10, 0],
    border: [1, 'solid'],
    borderRadius: 7,

    '& p': {
      color: 'inherit',
    },

    '& a': {
      color: 'inherit',
      fontWeight: 'bold',
    },
  },

  error: {
    borderColor: colorError,
    color: colorError,
  },

  warning: {
    borderColor: colorWarning,
    color: colorWarning,
  },

  success: {
    borderColor: colorSuccess,
    color: colorSuccess,
  },
});

export default styles;

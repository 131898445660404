// @flow

import React from 'react';
import cn from 'classnames';
import { withStyles } from '../../common/utils';
import styles from './Text.jss';


type Props = Styles<typeof styles> & {
  children: string,
  className: string,
};

function Text(props: Props) {
  const {
    classes,
    children,
    className,
    ...otherProps
  } = props;

  return (
    <p className={cn('text', className, classes.text)} {...otherProps}>
      {children}
    </p>
  );
}

export default withStyles(styles)(Text);

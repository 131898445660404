// @flow

const styles = ({
  stepTitleColor,
  stepActiveTitleColor,
  stepHintColor,
  stepActiveHintColor,
  processColor,
  processActiveColor,
}: Theme) => ({
  step: {
    position: 'relative',
    paddingLeft: 36,

    // Left line
    '&:not(:last-child):before, &.active:before': {
      position: 'absolute',
      display: 'block',
      top: 24,
      bottom: 0,
      left: 10,
      borderLeft: [4, 'dashed', processColor],
      content: '""',
    },

    // The dot in the end of process
    '&.active:last-child:after': {
      position: 'absolute',
      left: 8,
      bottom: -4,
      width: 8,
      height: 8,
      borderRadius: '50%',
      background: processActiveColor,
      content: '""',
    },

    // Left line for active step
    '&.active:before': {
      borderColor: processActiveColor,
    },

    // Left line for done step
    '&.done:before': {
      borderLeftStyle: 'solid',
    },
  },

  header: {
    padding: [0, 0, 24],

    '.active &': {
      paddingBottom: 12,
    },

    // Outer circle
    '&:before': {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'block',
      width: 24,
      height: 24,
      border: [3, 'solid', processColor],
      borderRadius: '50%',
      content: '""',
    },

    // Outer circle active
    '$step.active &:before': {
      borderColor: processActiveColor,
    },

    // Inner circle for active step
    '$step.active &:after': {
      position: 'absolute',
      top: 9,
      left: 9,
      width: 6,
      height: 6,
      borderRadius: '50%',
      background: processActiveColor,
      content: '""',
    },
  },

  title: {
    margin: 0,
    fontSize: 24,
    fontWeight: 400,
    color: stepTitleColor,

    '$step.active &': {
      color: stepActiveTitleColor,
    },
  },

  hint: {
    margin: 0,
    padding: [12, 0, 12],
    fontSize: 16,
    fontWeight: 300,
    color: stepHintColor,

    '$step.active &': {
      color: stepActiveHintColor,
    },
  },

  value: {
    extend: 'hint',
    paddingBottom: 0,
  },

  content: {
    padding: [0, 0, 24],
  },
});

export default styles;

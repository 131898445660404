// @flow

import m from 'moment-timezone';

/**
 * Returns total offset between local timezone a supplier's one in milliseconds
 */
export function getTotalOffset(time: number): number {
  // m().local() represents the computer's time zone
  // m() represents the time zone set previously by moment.tz.setDefault() and means
  // the supplier's time zone
  return (m(time).local().utcOffset() - m(time).utcOffset()) * 60000;
}

/**
 * Change supplier's timezone to local preserving same value of time
 * For example:
 * 2019-01-15T10:00:00-05:00 will be converted to
 * 2019-01-15T10:00:00+02:00 local
 * This is useful when using JavaScript Date object, so that it always displays the time we got
 * from the server
 */
export function toLocalTimezone(time: number) {
  const offset = getTotalOffset(time);

  // Return a new timestamp
  return (new Date(time - offset)).getTime();
}

/**
 * Opposite function to getSameDateInLocalTimezone
 */
export function toDefaultTimezone(time: number) {
  const offset = getTotalOffset(time);

  // Return a new timestamp
  return (new Date(time + offset)).getTime();
}

// @flow

import React from 'react';
import cn from 'classnames';
import { withStyles } from '../../common/utils';
import styles from './Header.jss';


type Props = Styles<typeof styles> & {
  logo: string,
};

function Header(props: Props) {
  const {
    logo,
    classes,
  } = props;

  return (
    <div className={cn('header', classes.header)}>
      <div className={cn('header-logo', classes.logo)}>
        <img src={logo} alt="" />
      </div>
    </div>
  );
}

export default withStyles(styles)(Header);

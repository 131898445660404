// @flow

import { media } from '../../common/utils';

const styles = ({ boxBackground }: Theme) => ({
  box: {
    padding: 8,
    background: boxBackground,
    borderRadius: 3,
    boxShadow: [2, 4, 40, 0, 'rgba(0, 0, 0, 0.2)'],

    [media(480)]: {
      padding: 24,
    },
  },
});

export default styles;

// @flow

import React from 'react';
import { compose } from 'recompose';
import cn from 'classnames';
import { withStyles, withConfig } from '../../common/utils';
import Header from '../Header';
import Process from '../Process';
import Footer from '../Footer';
import styles from './Layout.jss';


type Props = Styles<typeof styles> & {
  config: BrandConfig,
};

function Layout(props: Props) {
  const { classes, config } = props;

  const { logo } = config;

  return (
    <div className={cn('layout', classes.layout)}>
      <Header logo={logo} />

      <div className={cn('layout-main', classes.main)}>
        <Process />
      </div>

      <Footer />
    </div>
  );
}

export default compose(
  withConfig,
  withStyles(styles),
)(Layout);

// @flow

function submitForm(formName: string) {
  const form = document.getElementById(formName);

  if (form) {
    form.dispatchEvent(new Event('submit', { cancelable: true }));
  }
}

export default submitForm;

// @flow

const styles = ({
  buttonBorderColor,
  buttonHoverBorderColor,
  buttonTextColor,
  buttonBackgroundColor,
  buttonDisabledBorderColor,
  buttonDisabledBackground,
  buttonDisabledColor,
}: Theme) => ({
  button: {
    transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    position: 'relative',
    userSelect: 'none',
    lineHeight: 1.15,
    whiteSpace: 'nowrap',
    touchAction: 'manipulation',
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: 0,
    fontWeight: 500,
    textAlign: 'center',
    outline: 0,
    padding: [0, 15],
    fontSize: '14px',
    height: 36,
    border: `2px solid ${buttonBorderColor}`,
    borderRadius: 0,
    background: buttonBackgroundColor,
    color: buttonTextColor,

    '&:hover': {
      borderColor: buttonHoverBorderColor,
    },
  },

  disabled: {
    borderColor: buttonDisabledBorderColor,
    cursor: 'not-allowed',
    color: buttonDisabledColor,
    background: buttonDisabledBackground,

    '&:hover': {
      borderColor: buttonDisabledBorderColor,
      color: buttonDisabledColor,
      background: buttonDisabledBackground,
    },
  },
});

export default styles;

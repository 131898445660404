// @flow

const styles = () => ({
  header: {
    marginBottom: 48,
    textAlign: 'center',
    background: 'linear-gradient(#000000, transparent)',
  },

  logo: {
    height: '100%',
    padding: 24,

    '& img': {
      maxWidth: '100%',
      maxHeight: 200,
    },
  },
});

export default styles;

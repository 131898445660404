// @flow

import React, { Component } from 'react';
import { ThemeProvider } from 'react-jss';
import cn from 'classnames';
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';
import moment from 'moment-timezone';
import get from 'lodash.get';
import { createTheme, withStyles, ConfigProvider } from '../../common/utils';
import Preloader from '../../common/elements/Preloader';
import Layout from '../Layout';
import Box from '../Box';
import styles from './AppLoader.jss';
import redeamFavicon from '../../assets/icons/favicon.ico';
import Text from '../Text';

function initializeGA(config: BrandConfig) {
  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.initialize(config.gaId);
  } else if (process.env.REACT_APP_GA_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
  }

  ReactGA.pageview(window.location.pathname + window.location.search);
}

type Props = {
  classes: typeof styles,
};

type State = {
  loading: boolean,
  config: ?BrandConfig,
  error: ?string,
};

const { hostname } = window.location;

class AppLoader extends Component<Props, State> {
  state = {
    loading: true,
    config: null,
    error: null,
  };

  componentDidMount() {
    const brandId = hostname.split('.')[0];

    // $FlowFixMe
    import(`../../brands/${brandId}/config/index`)
      .then((module: { default: BrandConfig }) => {
        initializeGA(module.default);

        this.setState({
          config: module.default,
          loading: false,
        });

        moment.tz.setDefault(module.default.timezone);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error('Dynamic chunk error:', err);
        }

        this.setState({
          loading: false,
          error: `Config not found for "${brandId}"`,
        });
      });
  }

  renderLoading() {
    const { classes } = this.props;
    const { loading } = this.state;

    if (!loading) {
      return null;
    }

    return (
      <div className={classes.container}>
        <h1 className={classes.title}>Online Web Check In</h1>

        <Box className={classes.wrapper}>
          <Preloader />
        </Box>
      </div>
    );
  }

  renderNotFound() {
    const { classes } = this.props;
    const { loading, config, error } = this.state;

    if (loading || config) {
      return null;
    }

    return (
      <div className={classes.container}>
        <Box className={classes.wrapper}>
          <Text className={classes.error}>Oops!</Text>
          <Text>{error}</Text>
        </Box>
      </div>
    );
  }

  renderHead() {
    const { config } = this.state;

    if (!config) {
      return (
        <Helmet>
          <link rel="shortcut icon" href={redeamFavicon} />
          <title>Online Web Check In</title>
        </Helmet>
      );
    }

    return (
      <Helmet>
        <link rel="shortcut icon" href={config.favicon} />
        <title>{config.name} - Online Web Check In</title>
      </Helmet>
    );
  }

  renderLayout() {
    const { config } = this.state;

    if (!config) {
      return null;
    }

    const { layoutComponent: CustomLayout } = config;

    return (
      <ConfigProvider value={config}>
        {CustomLayout ? (
          <CustomLayout />
        ) : (
          <Layout />
        )}
      </ConfigProvider>
    );
  }

  render() {
    const { classes } = this.props;
    const { config } = this.state;

    return (
      <ThemeProvider theme={createTheme(get(config, 'theme'))}>
        <div className={cn('app', classes.app)}>
          {this.renderHead()}
          {this.renderLoading()}
          {this.renderNotFound()}
          {this.renderLayout()}
        </div>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(AppLoader);

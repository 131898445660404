// @flow

const styles = () => ({
  stepError: {
    padding: 24,
    textAlign: 'center',
  },

  cancel: {
    border: 0,
    background: 'none',
    color: '#108ee9',
    marginTop: 12,
    marginLeft: 0,

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      borderColor: 'transparent',
    },

    '&:focus': {
      border: 'none',
    },
  },
});

export default styles;

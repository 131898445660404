// @flow

import * as React from 'react';
import cn from 'classnames';
import withStyles from '../../utils/withStyles';
import styles from './Button.jss';

type Props = {
  className?: string,
  disabled?: boolean,
  children: React.ComponentType<any>,
} & Styles<typeof styles>;

function Button({
  className,
  classes,
  children,
  disabled,
  ...props
}: Props) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={cn('button', classes.button, { [classes.disabled]: disabled }, className)}
      {...props}
    >
      {children}
    </button>
  );
}

export default withStyles(styles)(Button);

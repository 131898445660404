// @flow

import React from 'react';
import cn from 'classnames';
import { withStyles } from '../../common/utils';
import { ReactComponent as RedeamLogo } from '../../assets/icons/logo.svg';
import styles from './Footer.jss';


type Props = Styles<typeof styles> & {};

function Footer(props: Props) {
  const {
    classes,
  } = props;

  return (
    <div className={cn('footer', classes.footer)}>
      <span>Powered by</span>
      <a href="https://redeam.com" target="_blank" rel="noreferrer noopener">
        <RedeamLogo fill="rgba(255, 255, 255, 0.7)" />
      </a>
    </div>
  );
}

export default withStyles(styles)(Footer);

// @flow

import React from 'react';
import cn from 'classnames';
import withStyles from '../../utils/withStyles';
import styles from './Alert.jss';

type Props = AlertT & Styles<typeof styles>;

function Alert(props: Props) {
  const {
    type,
    title,
    message,
    htmlMessage,
    classes,
  } = props;

  return (
    <div className={cn('alert', classes.alert, classes[type])} data-testid="alert">
      <h3>{title}</h3>
      {message && (
        <p>{message}</p>
      )}
      {htmlMessage && (
        <div dangerouslySetInnerHTML={{ __html: htmlMessage }} />
      )}
    </div>
  );
}

export default withStyles(styles)(Alert);

import React from 'react';
import { ReactComponent as IconPreloader } from '../../../assets/icons/preloader.svg';
import { withStyles } from '../../utils';
import styles from './Preloader.jss';

type Props = Styles<typeof styles> & {
  fill?: string,
};

function Preloader({ classes, ...otherProps }: Props) {
  return (
    <IconPreloader className={classes.preloader} {...otherProps} />
  );
}

Preloader.defaultProps = {
  fill: null,
};

export default withStyles(styles)(Preloader);

// @flow

import React, { type ComponentType } from 'react';
import getDisplayName from './getDisplayName';
import { ConfigConsumer } from './configContext';

function withConfig<Props: {}, Component: ComponentType<Props>>(
  WrappedComponent: Component,
): ComponentType<$Diff<Props, { config: ?BrandConfig }>> {
  function WithConfig(props: Props) {
    return (
      <ConfigConsumer>
        {(config: ?BrandConfig) => (
          <WrappedComponent config={config} {...props} />
        )}
      </ConfigConsumer>
    );
  }

  WithConfig.displayName = `WithConfig(${getDisplayName(WrappedComponent)})`;

  return WithConfig;
}

export default withConfig;
